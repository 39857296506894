/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* global $ */
/// <reference path="../../../typings/browser.d.ts" />
import { QueryParameter, RouterOrigins } from '@deltasierra/frontend/routing/core';
import { TemplateId } from '@deltasierra/ids';
import { getEntries } from '@deltasierra/object-utilities';
import {
    Activity,
    AgencyId,
    ApplyActionTo,
    AssignedLocation,
    assignedLocationToLocationIdHierarchy,
    BuilderTemplateCategoryId,
    BuilderTemplateId,
    canSaveSupportedPlannerAsReadyToAction,
    Channel,
    Client,
    ClientId,
    CollectionId,
    DeletePlannerBody,
    ExportPlatforms,
    ExternalService,
    forbiddenDateThreshold,
    GetPlannerUserInfoBody,
    LocationId,
    LocationIdHierarchy,
    NewPlannerData,
    Planner,
    PlannerPublishedArtifact,
    PlannerUserInfo,
    RecurringEventData,
    Untyped,
    Upload,
} from '@deltasierra/shared';
import { oneLine } from 'common-tags';
import moment from 'moment-timezone';
import { MvIdentity } from '../account/mvIdentity';
import { PickerDisplay } from '../campaign/components/CampaignEventForm/TemplateOrCategoryPicker/values/picker-display.enum';
import { MvClientResource, mvClientResourceSID } from '../clients/mvClientResource';
import { $locationSID, $qSID, $routeParamsSID, $scopeSID } from '../common/angularData';
import { $modalInstanceSID, $modalSID } from '../common/angularUIBootstrapData';
import { GET_CONFIG_QUERY } from '../common/config';
import { GetConfig } from '../common/config/__graphqlTypes/GetConfig';
import { ConfirmModal, confirmModalSID } from '../common/confirmModal';
import { DataUtils } from '../common/dataUtils';
import { MvNotifier } from '../common/mvNotifier';
import { SentryService } from '../common/sentryService';
import { UploadContext, UploadService } from '../common/uploadService';
import { BuilderTemplateApiClient } from '../contentBuilder/builderTemplateApiClient';
import { GraphqlService } from '../graphql/GraphqlService';
import { convertIdToUniversalNodeId, parseAndValidateUniversalNodeId } from '../graphql/utils';
import { I18nService } from '../i18n';
import { IntroDataService } from '../intro/introDataService';
import { IntroWrapper } from '../intro/introWrapper';
import { MvLocation } from '../locations/mvLocation';
import { ModalInstance, ModalService } from '../typings/angularUIBootstrap/modalService';
import { IntroService } from './../intro/introService';
import { GetPlannerConfig } from './__graphqlTypes/GetPlannerConfig';
import { GET_PLANNER_CONFIG } from './GetPlannerConfig.query';
import { MvMultiplePlannerSaveCtrl } from './mvMultiplePlannerSaveCtrl';
import { MvPlanner } from './mvPlanner';
import { PlannerDateService } from './plannerDateService';
import ILocationService = angular.ILocationService;
import IQService = angular.IQService;
import IPromise = angular.IPromise;
import IScope = angular.IScope;
import IAngularEvent = angular.IAngularEvent;

interface AssignedLocationOption extends LocationIdHierarchy {
    selected?: boolean;
}

type StartingDateText = {
    dateInMonth: string;
    dayOfWeek: string;
    weekInMonth: string;
};

interface PlannerEventRouteParams {
    clientId: ClientId;
    date: Date;
    plannerId: number;
    recurringPlannerId?: number;
    locationId: LocationId;
}

export interface PlannerEventControllerScope extends ng.IScope {
    onChangePickerDisplayOption: (pickerDisplayOption: PickerDisplay) => void;
    onSelectCategory: (categoryId: BuilderTemplateCategoryId | null) => void;
    onSelectTemplate: (templateId: BuilderTemplateId | null) => void;
    onSelectBuildableTemplate: (buildableTemplateId: string | null) => void;
    onSelectCollection: (collectionId: CollectionId | null) => void;
    setCategoryIsExpiring: (isExpiring: false) => void;
}

export class MvPlannerEventCtrl {
    public static SID = 'mvPlannerEventCtrl';

    public static readonly $inject: string[] = [
        $scopeSID,
        $routeParamsSID,
        $locationSID,
        $qSID,
        $modalSID,
        UploadService.SID,
        mvClientResourceSID,
        MvLocation.SID,
        MvPlanner.SID,
        MvNotifier.SID,
        MvIdentity.SID,
        PlannerDateService.SID,
        confirmModalSID,
        DataUtils.SID,
        BuilderTemplateApiClient.SID,
        IntroService.SID,
        IntroDataService.SID,
        I18nService.SID,
        GraphqlService.SID,
        SentryService.SID,
    ];

    public BUILD_OPTIONS = {
        NO_ARTWORK_REQUIRED: 'NO_ARTWORK_REQUIRED',
        SELF_SERVICE: 'SELF_SERVICE',
        SUPPORT_REQUESTED: 'SUPPORT_REQUESTED',
    };

    public PLANNER_STATUSES = {
        PLANNED: 'PLANNED',
    };

    public pageTitle!: () => string;

    public planner!: Partial<Planner>;

    public recurringEventData?: RecurringEventData;

    public date: Date;

    public plannerUserInfo?: PlannerUserInfo;

    public startingDateText: StartingDateText;

    public client?: Client;

    public location?: AssignedLocation;

    public selectedChannel?: Channel | null;

    public legacyChannels?: Channel[];

    public editing = false;

    public isRecurringEvent = false;

    public isRecurringDataReadOnly = false;

    public datePickerIsOpen = {
        endsOn: false,
        startsOn: false,
    };

    public isSharedFieldsChanged = false;

    public assignedLocations: AssignedLocationOption[] = [];

    public isMultipleLocations = false;

    public supportedPlannerCount: number | null = null;

    public isExistingAndSupported = false;

    public uploadContext?: UploadContext;

    public buildOption: string | null = null;

    // TODO: convert to an enum
    public isDirty = false;

    // eslint-disable-next-line @typescript-eslint/ban-types
    public dirtyUnwatchers: Function[] = [];

    public dirtyChecks = 0;

    public dirtyModal: ModalInstance | null = null;

    public saving = false;

    public forbiddenDateThreshold = forbiddenDateThreshold;

    public runningIntro: IntroWrapper | undefined;

    public introPlanEnabled = false;

    public introBuildEnabled = false;

    public isExpiringTemplateCategory = false;

    public selectedCollectionId: CollectionId | null = null;

    public selectedCategoryId?: BuilderTemplateCategoryId | null = null;

    public selectedTemplateId?: BuilderTemplateId | null;

    public selectedBuildableTemplateId?: string | null;

    public selectedPickerDisplayOption: string = PickerDisplay.None;

    public isLinkCategoryAndTemplateEnabled = false;

    public areLinkedResourcesLoaded = false;

    private deletedUploads: Upload[] = [];

    public constructor(
        private readonly $scope: PlannerEventControllerScope,
        private readonly $routeParams: PlannerEventRouteParams,
        private readonly $location: ILocationService,
        private readonly $q: IQService,
        private readonly $modal: ModalService,
        private readonly uploadService: UploadService,
        mvClientResource: MvClientResource,
        private readonly mvLocation: MvLocation,
        private readonly mvPlanner: MvPlanner,
        private readonly mvNotifier: MvNotifier,
        private readonly mvIdentity: MvIdentity, // Might need to expose this as "identity"
        private readonly plannerDateService: PlannerDateService,
        confirmModal: ConfirmModal,
        private readonly dataUtils: DataUtils,
        private readonly builderTemplateApiClient: BuilderTemplateApiClient,
        private readonly introService: IntroService,
        private readonly introDataService: IntroDataService,
        private readonly i18n: I18nService,
        private readonly graphqlService: GraphqlService,
        private readonly sentryService: SentryService,
    ) {
        this.initialiseIntro();
        if (this.runningIntro) {
            // Do not use plannerDateService.parseDateUTC()! Does the wrong thing.
            this.date = new Date($routeParams.date);
            this.initialiseDataForIntro();
            this.startIntro();
        } else {
            // Do not use plannerDateService.parseDateUTC()! Does the wrong thing.
            this.date = new Date($routeParams.date);
            this.client = mvClientResource.get({ id: $routeParams.clientId });

            $scope.$on('$locationChangeStart', (event: Untyped, next: Untyped) => {
                if (this.isDirty && !this.runningIntro) {
                    event.preventDefault();
                    if (!this.dirtyModal) {
                        const url = $location.url();
                        this.dirtyModal = confirmModal.open(
                            i18n.text.common.leavePagePrompt.title(),
                            i18n.text.common.leavePagePrompt.confirm(),
                            () => {
                                this.isDirty = false;
                                this.dirtyModal = null;
                                $location.url(url);
                            },
                            () => {
                                this.dirtyModal = null;
                            },
                        );
                    }
                }
            });

            $scope.onSelectCollection = this.onSelectCollection.bind(this);
            $scope.onChangePickerDisplayOption = this.onChangePickerDisplayOption.bind(this);
            $scope.onSelectCategory = this.onSelectCategory.bind(this);
            $scope.onSelectTemplate = this.onSelectTemplate.bind(this);
            $scope.onSelectBuildableTemplate = this.onSelectBuildableTemplate.bind(this);
            $scope.setCategoryIsExpiring = this.setCategoryIsExpiring.bind(this);

            let initPlannerFunc;
            if ($routeParams.plannerId || $routeParams.recurringPlannerId) {
                // Editing
                initPlannerFunc = async () => this.initExisting();
            } else {
                // Creating
                initPlannerFunc = async () => this.initNew();
            }

            void this.$q
                .all([
                    mvLocation.getAssignedLocation($routeParams.locationId).then((data: AssignedLocation) => {
                        this.location = data;
                    }),
                    mvPlanner
                        .getSupportedPlannerCount($routeParams.locationId, this.date)
                        .then(res => {
                            this.supportedPlannerCount = res.data!.count;
                        })
                        .catch((data: any) => {
                            // eslint-disable-next-line no-console
                            console.log(
                                oneLine`
                                Failed to retrieve supported planner count for
                                ${$routeParams.locationId}, ${this.date}
                                `,
                                data,
                            );
                        }),
                    initPlannerFunc(),
                ])
                .then(() => this.initWatchers());

            void this.loadPlannerConfig();
        }

        this.startingDateText = {
            dateInMonth: moment.localeData().ordinal(this.date.getUTCDate()),
            dayOfWeek: this.getDayOfWeekText(),
            weekInMonth: this.getWeekInMonthText(),
        };
    }

    public getUploadGlyph(ext: string): string {
        return this.uploadService.getUploadGlyph(ext);
    }

    public onFileSelect($files: File[]): void {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.planner.hasOwnProperty('uploads')) {
            this.planner.uploads = [];
        }
        this.uploadService.upload($files, 'planner', this.planner.uploads!, this);
    }

    public deleteUpload(upload: Upload): void {
        if (this.planner.uploads) {
            const position = $.inArray(upload, this.planner.uploads);
            if (position > -1) {
                this.deletedUploads.push(this.planner.uploads[position]);
                this.planner.uploads.splice(position, 1);
            }
        }
    }

    public openDatePicker($event: IAngularEvent, datePickerName: string): void {
        $event.preventDefault();
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
        (this.datePickerIsOpen as Untyped)[datePickerName] = true;
    }

    public cancel(): void {
        this.isDirty = false;
        this.goToPlanner();
    }

    // eslint-disable-next-line max-statements
    public updatePlanner(applyActionTo: ApplyActionTo): IPromise<any> {
        const newPlannerData: NewPlannerData = this.planner as NewPlannerData;
        newPlannerData.clientId = this.$routeParams.clientId;
        newPlannerData.locationId = this.$routeParams.locationId;
        newPlannerData.date = this.$routeParams.date;
        newPlannerData.deletedUploads = this.isMultipleLocations ? null : this.deletedUploads;
        newPlannerData.channels = this.selectedChannel ? [this.selectedChannel.id] : [];
        newPlannerData.activityId = this.selectedChannel ? this.selectedChannel.activityId : null;
        newPlannerData.uploads = this.isMultipleLocations ? null : newPlannerData.uploads;
        newPlannerData.buildableTemplateId = this.selectedBuildableTemplateId
            ? parseAndValidateUniversalNodeId<TemplateId>(this.selectedBuildableTemplateId)
            : null;
        newPlannerData.builderTemplateCategoryId = this.selectedCategoryId;
        newPlannerData.builderTemplateId = this.selectedTemplateId;
        newPlannerData.collectionId = this.selectedCollectionId;

        const payload = {
            applyActionTo,
            planner: newPlannerData,
            recurringEventData: this.isRecurringEvent ? this.recurringEventData : null,
        };

        if (this.$routeParams.plannerId) {
            // Editing
            newPlannerData.plannerId = this.$routeParams.plannerId;
            this.saving = true;
            return this.mvPlanner.updatePlanner(payload).then(
                (savedPlanner: Untyped) => {
                    this.unwatchDirtyChanges();
                    this.isDirty = false;
                    this.mvNotifier.notify(this.i18n.text.plan.notifyPlannerUpdated({ planner: newPlannerData.title }));
                    this.planner.id = savedPlanner.id;
                },
                (data: Untyped) => {
                    this.saving = false;
                    this.mvNotifier.unexpectedErrorWithData(
                        this.i18n.text.plan.event.failedToCreateUpdatePlanner(),
                        data,
                    );
                    return this.$q.reject(data);
                },
            );
        } else {
            // Creating
            const currentLocation = this.location!;
            const locations: LocationIdHierarchy[] = [assignedLocationToLocationIdHierarchy(currentLocation)];
            if (this.isMultipleLocations) {
                for (const location of this.assignedLocations) {
                    if (location.selected && this.planner.locationId !== location.locationId) {
                        locations.push(location);
                    }
                }
                // TODO: sort all locations by title
            }
            this.saving = true;
            const modalInstance = this.$modal.open({
                backdrop: 'static',
                controller: MvMultiplePlannerSaveCtrl,
                controllerAs: 'ctrl',
                resolve: {
                    currentLocationId: () => this.planner.locationId,
                    locations: () => locations,
                    payloadData: () => payload,
                },
                templateUrl: '/partials/planner/multiplePlannerSave',
            });
            return modalInstance.result.then(
                (result: number) => {
                    this.saving = false;
                    this.isDirty = false;
                    this.unwatchDirtyChanges();
                    this.planner.id = result;
                },
                () => {
                    this.saving = false;
                    return this.$q.reject();
                },
            );
        }
    }

    public clickUpdatePlannerReadyToAction(): IPromise<void> {
        if (this.planner) {
            if (!canSaveSupportedPlannerAsReadyToAction(this.mvIdentity.currentUser!, this.planner as Planner)) {
                return this.openUnsupportedPlannerDialog('plannerEvent/unsupportedDateModal.html');
            } else {
                this.planner.contentSupplied = true;
                return this.innerClickUpdatePlanner();
            }
        } else {
            return Promise.resolve(undefined);
        }
    }

    public clickUpdatePlannerMoreContentRequired(): IPromise<void> {
        this.planner.contentSupplied = false;
        return this.innerClickUpdatePlanner();
    }

    public saveAndGoToBuilder(): IPromise<void> {
        this.planner.contentSupplied = true;
        if (this.planner.recurringPlannerId && this.isSharedFieldsChanged) {
            return this.openUpdateModalDialog().then((applyActionTo: ApplyActionTo) =>
                this.updatePlannerEventAndRedirectToBuilder(applyActionTo),
            );
        } else {
            return this.updatePlannerEventAndRedirectToBuilder('current');
        }
    }

    // eslint-disable-next-line max-statements
    public async openDeleteModalDialog(): Promise<void> {
        const newScope: IScope & Partial<MvPlannerDeleteEventModalCtrlPropsScope> = this.$scope.$new();
        newScope.plannerTitle = this.planner.title;
        newScope.isScheduled = false;
        newScope.isPublished = false;
        newScope.platform = null;

        let publishedArtifacts: PlannerPublishedArtifact[] = [];

        if (this.planner.id) {
            publishedArtifacts = await this.mvPlanner.getPlannerPublishedArtifacts(this.planner.id);
        }

        if (publishedArtifacts.length > 0) {
            newScope.isScheduled = true;
            newScope.isPublished = publishedArtifacts[0].isPublished;
            const matchingChannel = getEntries(ExportPlatforms)
                .map(([_, platform]) => platform)
                .find(platform =>
                    Array.isArray(platform)
                        ? (platform as ExternalService[]).find(
                            multiPlatform => multiPlatform.name === publishedArtifacts[0].channel,
                        )
                        : (platform as ExternalService).name === publishedArtifacts[0].channel,
                );
            newScope.platform = matchingChannel
                ? (matchingChannel as ExternalService).displayName
                : `${this.i18n.text.common.selected} ${this.i18n.text.common.platform}`;
        }

        try {
            const modalInstance = this.$modal.open({
                controller: 'MvPlannerDeleteEventModalCtrl',
                scope: newScope,
                templateUrl: 'modalDeleteEventConfirmation.html',
            });

            await modalInstance.result;

            if (this.planner.recurringPlannerId) {
                const recurringModalInstance = this.$modal.open({
                    controller: 'mvPlannerEventApplyActionToModalCtrl',
                    templateUrl: 'modalDeleteRecurringConfirmation.html',
                });
                const applyActionTo: ApplyActionTo = await recurringModalInstance.result;
                return this.deletePlanner(applyActionTo);
            } else {
                return this.deletePlanner(ApplyActionTo.current);
            }
        } catch (error) {
            if (error === 'cancel' || error === 'backdrop click' || error === 'escape key press') {
                // eslint-disable-next-line consistent-return
                return;
            }

            throw error;
        }
    }

    public openUpdateModalDialog(): IPromise<any> {
        const modalInstance = this.$modal.open({
            controller: 'mvPlannerEventApplyActionToModalCtrl',
            templateUrl: 'modalUpdateRecurringConfirmation.html',
        });

        return modalInstance.result;
    }

    public canChooseAdditionalLocations(): boolean {
        if (!this.location || !this.assignedLocations || this.assignedLocations.length < 2) {
            return false;
        }
        const sameClientLocations = this.dataUtils.filterBy('clientId', this.assignedLocations, this.location.clientId);
        return sameClientLocations.length > 1;
    }

    public chooseAdditionalLocations(): IPromise<void> {
        const newScope: IScope & { preselectedLocations?: LocationIdHierarchy[] } = this.$scope.$new();
        const selectedLocations = this.dataUtils.filterBy('selected', this.assignedLocations, true);
        newScope.preselectedLocations = selectedLocations;
        const modalInstance = this.$modal.open({
            controller: 'mvAdditionalLocationsCtrl',
            scope: newScope,
            templateUrl: '/partials/planner/additionalLocations',
        });

        return modalInstance.result.then((additionalLocations: LocationIdHierarchy[]) => {
            const locationIds = this.dataUtils.pluckByGetter(loc => loc.locationId, additionalLocations);
            for (const location of this.assignedLocations) {
                const isSelected = locationIds.indexOf(location.locationId) > -1;
                location.selected = isSelected;
            }
        });
    }

    public isDateSupportedByAgency(): boolean {
        return canSaveSupportedPlannerAsReadyToAction(this.mvIdentity.currentUser!, this.planner as Planner);
    }

    public isUnderSupportedPlannerLimit(): boolean {
        return this.supportedPlannerCount! < this.location!.supportedPlannerLimit!;
    }

    public async goToBuilder(): Promise<void> {
        // TODO: Need to include suggested collection, see https://digitalstack.atlassian.net/browse/DSL-4613
        if (this.planner.buildableTemplateId && this.planner.locationId && this.planner.clientId && this.planner.id) {
            const environmentConfig = await this.getEnvironmentConfig();
            const redirectUrl = `${environmentConfig.config.appFrontendUrl}/publish/email/?${new URLSearchParams({
                [QueryParameter.ClientId]: convertIdToUniversalNodeId('client', this.planner.clientId),
                [QueryParameter.LocationId]: convertIdToUniversalNodeId('location', this.planner.locationId),
                [QueryParameter.TemplateId]: convertIdToUniversalNodeId(
                    'BuildableTemplate',
                    this.planner.buildableTemplateId,
                ),
                [QueryParameter.PlannerId]: this.planner.id.toString(),
                [QueryParameter.Origin]: RouterOrigins.OLD_TEMPLATE_GALLERY,
            })}`;
            window.location.replace(redirectUrl);
            return;
        }

        const query: { [key: string]: any } = {
            planner: this.planner.id,
        };

        if (this.planner.collectionId) {
            query.collection = this.selectedCollectionId;
        }

        if (this.planner.builderTemplateId) {
            const isEmail = await this.checkIsEmail(this.planner.builderTemplateId);
            const path = isEmail ? 'emailBuilder' : 'contentBuilder';

            query.template = this.planner.builderTemplateId;

            this.$location.path(path).search(query);
            return;
        }

        query.location = this.planner.locationId;

        if (this.selectedChannel && this.selectedChannel.platformId) {
            query.platform = this.selectedChannel.platformId;
        }
        if (this.selectedCategoryId) {
            query.category = this.selectedCategoryId;
        }
        this.$location.path('/builderTemplateGallery').search(query);
    }

    public canAccessBuilder(): boolean {
        return this.mvIdentity.canAccessBuilder();
    }

    public getActivity(activityId: Untyped): Activity | null {
        if (!this.client || !this.client.activities) {
            return null;
        }
        const activity = this.dataUtils.findBy('id', this.client.activities, activityId);
        return activity;
    }

    public onSelectChannel(channel: Channel): void {
        // Bit of a hack to prevent dirty flag set when setting the initial channel.
        if ((channel && !this.selectedChannel) || this.selectedChannel!.id !== channel.id) {
            this.selectedChannel = channel;
            this.planner.activityId = this.selectedChannel.activityId;
            if (this.selectedChannel.isSelfService) {
                this.buildOption = this.BUILD_OPTIONS.SELF_SERVICE;
            } else if (channel.isSupported) {
                this.buildOption = this.BUILD_OPTIONS.SUPPORT_REQUESTED;
            } else {
                this.buildOption = this.BUILD_OPTIONS.NO_ARTWORK_REQUIRED;
            }
        }
    }

    public onSelectCollection(collectionId: CollectionId | null): void {
        this.selectedCollectionId = collectionId;
    }

    public onChangePickerDisplayOption(pickerDisplayOption: string): void {
        this.selectedPickerDisplayOption = pickerDisplayOption;
    }

    public onSelectCategory(categoryId: BuilderTemplateCategoryId | null): void {
        this.selectedTemplateId = null;
        this.selectedBuildableTemplateId = null;
        this.selectedCategoryId = categoryId;
    }

    public setCategoryIsExpiring(isExpiring: false): void {
        this.isExpiringTemplateCategory = isExpiring;
    }

    public onSelectTemplate(templateId: BuilderTemplateId | null): void {
        this.selectedCategoryId = null;
        this.selectedBuildableTemplateId = null;
        this.selectedTemplateId = templateId;
    }

    public onSelectBuildableTemplate(buildableTemplateId: string | null): void {
        this.selectedCategoryId = null;
        this.selectedTemplateId = null;
        this.selectedBuildableTemplateId = buildableTemplateId;
    }

    public checkPayloadValidity(): boolean {
        const isInvalid = !!(
            this.uploadContext?.uploadInProgress ||
            !this.selectedChannel ||
            this.saving ||
            (this.selectedPickerDisplayOption === PickerDisplay.Category && !this.selectedCategoryId) ||
            (this.selectedPickerDisplayOption === PickerDisplay.Template && !this.selectedTemplateId && !this.selectedBuildableTemplateId)
        );
        return isInvalid;
    }

    public async loadPlannerConfig(): Promise<void> {
        const gqlClient = this.graphqlService.getClient();
        const configResult = await gqlClient.query<GetPlannerConfig>({
            fetchPolicy: 'cache-first',
            notifyOnNetworkStatusChange: true,
            query: GET_PLANNER_CONFIG,
        });
        if (configResult.errors) {
            this.sentryService.captureException('Failed to fetch "GET_PLANNER_CONFIG" GraphQL query', {
                errors: configResult.errors,
            });
            throw new Error('Failed to fetch planner config');
        }

        if (configResult.data) {
            this.isLinkCategoryAndTemplateEnabled = configResult.data.config.features.planner.linkTemplateAndCategory;
        }
    }

    private async getEnvironmentConfig(): Promise<GetConfig> {
        const gqlClient = this.graphqlService.getClient();

        const configResult = await gqlClient.query<GetConfig>({
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            query: GET_CONFIG_QUERY,
        });

        if (configResult.errors) {
            throw new Error('Failed to fetch config');
        }

        return configResult.data;
    }

    private async checkIsEmail(id: number): Promise<boolean> {
        const template = await this.builderTemplateApiClient.getBuilderTemplate(id);
        return template.documentFormat === 'DSETDoc';
    }

    private onBuildOptionChange() {
        if (!this.planner) {
            return Promise.resolve(undefined);
        }
        this.planner.isSupported = false;
        this.planner.isArtifactRequired = false;
        if (this.buildOption === this.BUILD_OPTIONS.SUPPORT_REQUESTED) {
            if (!this.isExistingAndSupported) {
                if (!this.isDateSupportedByAgency()) {
                    this.selectedChannel = null;
                    this.buildOption = this.BUILD_OPTIONS.NO_ARTWORK_REQUIRED;
                    return this.openUnsupportedDateDialog();
                } else if (!this.isUnderSupportedPlannerLimit()) {
                    this.selectedChannel = null;
                    this.buildOption = this.BUILD_OPTIONS.NO_ARTWORK_REQUIRED;
                    return this.openOverPlannerLimitDialog();
                }
            }
            this.planner.isSupported = true;
        } else if (this.buildOption === this.BUILD_OPTIONS.SELF_SERVICE) {
            this.planner.isArtifactRequired = true;
        }
        return Promise.resolve(undefined);
    }

    private onDirtyChange(newValue: any, oldValue: any): void {
        if (!this.planner || newValue === oldValue) {
            return;
        }
        this.dirtyChecks++;
        if (this.dirtyChecks > 0) {
            this.isDirty = true;
        }
    }

    private initialiseIntro() {
        this.introPlanEnabled = this.introService.isIntroActive('plan');
        this.introBuildEnabled = this.introService.isIntroActive('build');

        if (this.introPlanEnabled || this.introBuildEnabled) {
            if (this.introPlanEnabled) {
                this.runningIntro = this.introService.setUpIntro('plan', this.$scope);
            } else if (this.introBuildEnabled) {
                this.runningIntro = this.introService.setUpIntro('build', this.$scope);
            } else {
                throw new Error('Tried to initialise an intro, but no supported intro is active.');
            }
        }
    }

    private initialiseDataForIntro() {
        this.editing = true;
        this.pageTitle = () => this.i18n.text.plan.event.editPlanner();
        this.planner = {
            activityId: null,
            assignedToId: null,
            clientId: this.$routeParams.clientId,
            contentSupplied: false,
            createdAt: new Date(),
            createdById: undefined,
            date: this.$routeParams.date,
            expectedLeads: null,
            id: 0,
            instructions: null,
            isArtifactRequired: true,
            isSupported: false,
            locationId: this.$routeParams.locationId,
            maxSpend: null,
            postedBy: 'manager',
            recurringPlannerId: null,
            status: 'planned',
            text: 'This is an example text', // TODO: translate this
            title: 'Example Planner', // TODO: translate this
            updatedAt: new Date(),
            updatedById: undefined,
            uploads: [],
        };
        this.client = {
            activities: [this.introDataService.dummyActivity],
            agencyId: AgencyId.from(0),
            billerCode: null,
            brandId: null,
            country: 'AU',
            createdAt: new Date(),
            createdById: null,
            currency: 'AUD',
            defaultSupportedPlannerLimit: 10,
            deletedAt: null,
            deletedById: null,
            facebookAdAccountId: null,
            facebookAdAccountName: null,
            fitwareBusinessCode: null,
            fitwareBusinessName: null,
            fitwareConversionAmountInCents: 0,
            fitwareConversionCurrency: 'AUD',
            four51Company: null,
            four51SsoKey: null,
            four51Store: null,
            googleMyBusinessAccountName: null,
            graphqlId: '',
            id: ClientId.from(1),
            notificationEmailAddresses: [],
            showGroupsOnDashboard: false,
            styleGuideUploadId: null,
            title: 'Demo Client', // TODO: Translate this
            updatedAt: new Date(),
            updatedById: null,
        };
        this.location = {
            agencyGraphqlId: '-',
            agencyId: AgencyId.from(1),
            agencyName: 'Demo Agency',
            campaignMonitorClientId: null,
            client: 'Demo Client',
            clientGraphqlId: '-',
            clientId: ClientId.from(1),
            facebookPageId: null,
            facebookPageName: null,
            fitwareAccountId: null,
            fitwareClubBrand: null,
            fitwareClubEmail: null,
            fitwareClubName: null,
            fitwareClubNumber: null,
            googleAdWordsAccountId: null,
            googleAdWordsCampaignName: null,
            googleAdWordsCustomerId: null,
            googleAnalyticsPagePath: null,
            googleAnalyticsPropertyId: null,
            googleAnalyticsViewId: null,
            googleMyBusinessAccountName: null,
            googleMyBusinessLocationId: null,
            graphqlId: '-',
            gymSalesCompanyId: null,
            gymSalesCompanyName: null,
            id: LocationId.from(0),
            instagramFacebookPageId: null,
            instagramFacebookPageName: null,
            instagramPageId: null,
            instagramPageName: null,
            latitude: null,
            linkedInOrganizationUrn: null,
            longitude: null,
            mailchimpServer: null,
            shippingAttention: null,
            shippingCompanyName: null,
            shippingLineOne: null,
            shippingLineThree: null,
            shippingLineTwo: null,
            supportedPlannerLimit: null,
            timeOffset: '+00:00',
            timezone: 'Australia/Melbourne',
            title: 'Demo Location',
        };
        this.selectedChannel = this.introDataService.dummyChannel;
        this.buildOption = this.BUILD_OPTIONS.SELF_SERVICE;
    }

    private startIntro() {
        if (this.runningIntro) {
            this.introService.startIntro(this.runningIntro);
        }
    }

    private initWatchers() {
        this.$scope.$watch(() => this.buildOption, this.onBuildOptionChange.bind(this));
        if (this.editing) {
            const unwatchers = [];
            let unwatcher;

            unwatcher = this.$scope.$watch(() => this.planner, this.onDirtyChange.bind(this), true);
            unwatchers.push(unwatcher);

            unwatcher = this.$scope.$watch(() => this.isRecurringEvent, this.onDirtyChange.bind(this));
            unwatchers.push(unwatcher);

            unwatcher = this.$scope.$watch(() => this.selectedChannel, this.onDirtyChange.bind(this));
            unwatchers.push(unwatcher);

            this.dirtyUnwatchers = unwatchers;
        }
    }

    private unwatchDirtyChanges() {
        for (const func of this.dirtyUnwatchers) {
            func();
        }
    }

    private async initNew() {
        this.pageTitle = () => this.i18n.text.plan.event.addToPlanner();
        this.planner = {
            activityId: undefined,
            assignedToId: undefined,
            clientId: this.$routeParams.clientId,
            contentSupplied: undefined,
            createdAt: undefined,
            createdById: undefined,
            date: this.$routeParams.date,
            expectedLeads: undefined,
            id: undefined,
            instructions: undefined,
            isArtifactRequired: undefined,
            isSupported: undefined,
            locationId: this.$routeParams.locationId,
            maxSpend: undefined,
            postedBy: undefined,
            recurringPlannerId: undefined,
            status: undefined,
            text: undefined,
            title: undefined,
            updatedAt: undefined,
            updatedById: undefined,
            uploads: [],
        };
        this.editing = false;
        this.isDirty = true;
        if (this.mvIdentity.isManager()) {
            this.planner.postedBy = this.i18n.text.common.manager();
        }
        if (this.mvIdentity.isClient()) {
            this.planner.postedBy = this.i18n.text.common.client();
        }
        this.initDefaultRecurringEventData();
        return this.mvLocation
            .getAssignedLocations()
            .then((data: LocationIdHierarchy[]) => {
                this.assignedLocations = data;
            })
            .catch(data => {
                this.mvNotifier.unexpectedErrorWithData(this.i18n.text.common.failedToRetrieveLocations(), data);
            })
            .finally(() => {
                this.areLinkedResourcesLoaded = true;
            });
    }

    // eslint-disable-next-line max-statements
    private async initExisting() {
        this.editing = true;
        this.pageTitle = () => this.i18n.text.plan.event.editPlanner();
        const result: Untyped = await new Promise(resolve => {
            void this.mvPlanner
                .getPlanner(
                    this.$routeParams.clientId,
                    this.$routeParams.locationId,
                    this.$routeParams.date,
                    this.$routeParams.plannerId,
                    this.$routeParams.recurringPlannerId,
                )
                .success((data: Untyped) => resolve(data))
                .error((data: any) => {
                    this.mvNotifier.unexpectedErrorWithData(this.i18n.text.plan.event.failedToRetrievePlanner(), data);
                });
        });
        this.planner = result.planner;
        this.isExistingAndSupported = !!this.planner.isSupported;
        this.selectedCategoryId = result.planner.builderTemplateCategoryId;
        this.selectedTemplateId = result.planner.builderTemplateId;
        this.selectedBuildableTemplateId = result.planner.buildableTemplateId
            ? convertIdToUniversalNodeId('BuildableTemplate', result.planner.buildableTemplateId)
            : null;
        this.selectedPickerDisplayOption = result.planner.builderTemplateCategoryId
            ? PickerDisplay.Category
            : PickerDisplay.Template;

        if (result.planner.builderTemplateCategoryId) {
            this.selectedPickerDisplayOption = PickerDisplay.Category;
        } else if (result.planner.builderTemplateId || result.planner.buildableTemplateId) {
            this.selectedPickerDisplayOption = PickerDisplay.Template;
        } else {
            this.selectedPickerDisplayOption = PickerDisplay.None;
        }

        this.selectedCollectionId = result.planner.collectionId;
        this.initChannelsForExisting();
        this.initBuildOptionForExisting();
        await this.initUserInfoForExisting();

        if (result.recurringEventData) {
            this.recurringEventData = result.recurringEventData;
            this.initRecurringEventDataForExisting();
        } else {
            this.initDefaultRecurringEventData();
        }
        this.areLinkedResourcesLoaded = true;
    }

    private async initUserInfoForExisting() {
        const result: PlannerUserInfo = await new Promise(resolve => {
            const body: GetPlannerUserInfoBody = {
                plannerId: this.$routeParams.plannerId,
                recurringPlannerId: this.$routeParams.recurringPlannerId,
            };
            void this.mvPlanner
                .getPlannerUserInfo(body)
                .success(data => resolve(data))
                .error((data: any) => {
                    this.mvNotifier.unexpectedErrorWithData(
                        this.i18n.text.plan.event.failedToRetrievePlannerInfo(),
                        data,
                    );
                });
        });
        this.plannerUserInfo = result;
    }

    private initChannelsForExisting() {
        return (this.client as Untyped).$promise.then(() => {
            let selectedChannel = null;
            const legacyChannels = [];
            if (this.planner && this.planner.channels && this.planner.channels.length > 0) {
                for (const channel of this.planner.channels) {
                    const activity = this.getActivity(channel.activityId);
                    if (!channel.deletedAt && activity && !activity.deletedAt && selectedChannel === null) {
                        selectedChannel = channel;
                    } else {
                        legacyChannels.push(channel);
                    }
                }
            }
            this.selectedChannel = selectedChannel;
            this.legacyChannels = legacyChannels;
        });
    }

    private initRecurringEventDataForExisting() {
        this.isRecurringEvent = true;

        // Set up watchers to detect for changes to shared fields. TODO: move this to initWatchers
        // eslint-disable-next-line @typescript-eslint/ban-types
        const watchDeregisterFuncs: Function[] = [];
        const onChangeFunc = <T>(newVal: T, oldVal: T) => {
            if (oldVal !== newVal) {
                // Console.log("a field was changed from " + oldVal + " to " + newVal);
                this.isSharedFieldsChanged = true;
                for (const watchDeregisterFunc of watchDeregisterFuncs) {
                    watchDeregisterFunc();
                }
            }
        };
        const watchExpressions = ['ctrl.isRecurringEvent', 'ctrl.selectedChannel'];
        // Fields on planner object shared by recurring planner
        const recurringPlannerSharedFields: Array<keyof Planner> = [
            'title',
            'text',
            // 'maxSpend',
            // 'expectedLeads',
            // 'postedBy',
            'activityId', // Doesn't work - not updated until after save is clicked
            'channels', // Doesn't work - not updated until after save is clicked
            'isSupported', // Implicit with choice of channel
            'isArtifactRequired',
        ]; // Implicit with choice of channel
        angular.forEach(recurringPlannerSharedFields, (fieldName, key) => {
            watchExpressions.push(`ctrl.planner.${fieldName}`);
        });
        angular.forEach(watchExpressions, (watchExpression, key) => {
            watchDeregisterFuncs.push(this.$scope.$watch(watchExpression, onChangeFunc));
        });
        // Do deep dirty checking of this object
        watchDeregisterFuncs.push(this.$scope.$watch('ctrl.recurringEventData', onChangeFunc, true));

        // Disable editing the recurring event data - will need to enable this functionality as a future task.
        this.isRecurringDataReadOnly = true;
    }

    private initBuildOptionForExisting() {
        if (this.planner.isSupported === true) {
            this.buildOption = this.BUILD_OPTIONS.SUPPORT_REQUESTED;
        } else if (this.planner.isArtifactRequired === true) {
            this.buildOption = this.BUILD_OPTIONS.SELF_SERVICE;
        } else {
            this.buildOption = this.BUILD_OPTIONS.NO_ARTWORK_REQUIRED;
        }
    }

    private initDefaultRecurringEventData() {
        this.recurringEventData = {
            ends: {
                after: null,
                on: this.date,
                type: 'never',
            },
            repeatBy: 'daily',
            startsOn: this.date,
        };
    }

    private goToPlanner() {
        this.$location.path('/planner');
    }

    private deletePlanner(applyActionTo: ApplyActionTo) {
        const plannerData: DeletePlannerBody = {
            applyActionTo,
            date: this.date,
            locationId: this.$routeParams.locationId,
            plannerId: this.$routeParams.plannerId,
            recurringPlannerId: this.planner.recurringPlannerId,
        };
        return this.mvPlanner.deletePlanner(plannerData).then(
            () => {
                this.mvNotifier.notify(this.i18n.text.plan.notifyPlannerEventDeleted());
                this.isDirty = false;
                this.goToPlanner();
            },
            (data: any) => {
                this.mvNotifier.unexpectedErrorWithData(this.i18n.text.plan.event.failedToDeletePlanner(), data);
            },
        );
    }

    private updatePlannerEventAndRedirectToPlanner(applyActionTo: Untyped) {
        return this.updatePlanner(applyActionTo).then(
            () => {
                this.goToPlanner();
            },
            () => {
                // Do nothing
            },
        );
    }

    private updatePlannerEventAndRedirectToBuilder(applyActionTo: Untyped) {
        return this.updatePlanner(applyActionTo).then(
            () => {
                void this.goToBuilder();
            },
            () => {
                // Do nothing
            },
        );
    }

    private innerClickUpdatePlanner() {
        if (this.planner.recurringPlannerId && this.isSharedFieldsChanged) {
            return this.openUpdateModalDialog().then((applyActionTo: ApplyActionTo) =>
                this.updatePlannerEventAndRedirectToPlanner(applyActionTo),
            );
        } else {
            return this.updatePlannerEventAndRedirectToPlanner('current');
        }
    }

    private openUnsupportedPlannerDialog(templateUrl: Untyped) {
        const modalInstance = this.$modal.open({
            controller: 'mvPlannerEventUnsupportedModalCtrl',
            templateUrl,
        });
        return modalInstance.result.then((result: Untyped) => {
            if (result === 'specialRequest') {
                const params = {
                    brief: this.planner.text,
                    date: this.plannerDateService.formatDateUTC(this.date, 'yyyy-MM-dd'),
                    locationId: this.planner.locationId,
                    new: true,
                    title: this.planner.title,
                };
                this.$location.path('/specialRequests').search(params);
            }
        });
    }

    private openUnsupportedDateDialog() {
        return this.openUnsupportedPlannerDialog('plannerEvent/unsupportedDateModal.html');
    }

    private openOverPlannerLimitDialog() {
        return this.openUnsupportedPlannerDialog('plannerEvent/overPlannerLimit.html');
    }

    private getDayOfWeekText(): string {
        const day = moment.utc(this.date).format('dddd');
        switch (day) {
            case 'Monday':
                return this.i18n.text.plan.event.monday();
            case 'Tuesday':
                return this.i18n.text.plan.event.tuesday();
            case 'Wednesday':
                return this.i18n.text.plan.event.wednesday();
            case 'Thursday':
                return this.i18n.text.plan.event.thursday();
            case 'Friday':
                return this.i18n.text.plan.event.friday();
            case 'Saturday':
                return this.i18n.text.plan.event.saturday();
            case 'Sunday':
                return this.i18n.text.plan.event.sunday();
            default:
                return '';
        }
    }

    private getWeekInMonthText(): string {
        // Based on: https://github.com/moment/moment/issues/2934#issuecomment-182541809
        const date = moment.utc(this.date).date();
        if (date <= 7) {
            return this.i18n.text.plan.event.first().toLocaleLowerCase();
        } else if (date > 7 && date <= 14) {
            return this.i18n.text.plan.event.second().toLocaleLowerCase();
        } else if (date > 14 && date <= 21) {
            return this.i18n.text.plan.event.third().toLocaleLowerCase();
        } else if (date > 21 && date <= 28) {
            return this.i18n.text.plan.event.fourth().toLocaleLowerCase();
        } else {
            return this.i18n.text.plan.event.fifth().toLocaleLowerCase();
        }
    }
}

angular.module('app').controller(MvPlannerEventCtrl.SID, MvPlannerEventCtrl);

export interface MvPlannerEventApplyActionToModalCtrlScope extends IScope {
    chooseAll(): void;
    chooseFuture(): void;
    chooseCurrent(): void;
    cancel(): void;
}

class MvPlannerEventApplyActionToModalController {
    public static SID = 'mvPlannerEventApplyActionToModalCtrl';

    public static readonly $inject: string[] = [$scopeSID, $modalInstanceSID];

    public constructor($scope: MvPlannerEventApplyActionToModalCtrlScope, $modalInstance: ModalInstance) {
        $scope.chooseAll = function chooseAll() {
            $modalInstance.close('all');
        };

        $scope.chooseFuture = function chooseFuture() {
            $modalInstance.close('future');
        };

        $scope.chooseCurrent = function chooseCurrent() {
            $modalInstance.close('current');
        };

        $scope.cancel = function cancel() {
            $modalInstance.dismiss('cancel');
        };
    }
}

angular
    .module('app')
    .controller(MvPlannerEventApplyActionToModalController.SID, MvPlannerEventApplyActionToModalController);

export interface MvPlannerDeleteEventModalCtrlPropsScope extends IScope {
    plannerTitle: string;
    isScheduled: boolean;
    isPublished: boolean;
    platform?: string | null;
}

export interface MvPlannerDeleteEventModalCtrlScope extends MvPlannerDeleteEventModalCtrlPropsScope {
    confirmDelete(): void;
    cancel(): void;
}

class MvPlannerDeleteEventModalController {
    public static SID = 'MvPlannerDeleteEventModalCtrl';

    public static readonly $inject: string[] = [$scopeSID, $modalInstanceSID];

    public constructor($scope: MvPlannerDeleteEventModalCtrlScope, $modalInstance: ModalInstance) {
        $scope.confirmDelete = function confirmDelete() {
            $modalInstance.close();
        };

        $scope.cancel = function cancel() {
            $modalInstance.dismiss('cancel');
        };
    }
}

angular.module('app').controller(MvPlannerDeleteEventModalController.SID, MvPlannerDeleteEventModalController);

export interface MvPlannerEventUnsupportedModalCtrl extends IScope {
    isSpecialRequester: boolean;
    supportedDateThreshold: number;
    cancel(): void;
    specialRequest(): void;
}

class MvPlannerEventUnsupportedModalController {
    public static SID = 'mvPlannerEventUnsupportedModalCtrl';

    public static readonly $inject: string[] = [$scopeSID, $modalInstanceSID, PlannerDateService.SID, MvIdentity.SID];

    public constructor(
        $scope: MvPlannerEventUnsupportedModalCtrl,
        $modalInstance: ModalInstance,
        plannerDateService: PlannerDateService,
        mvIdentity: MvIdentity,
    ) {
        $scope.isSpecialRequester = mvIdentity.isManager() || mvIdentity.isSpecialRequester();
        $scope.supportedDateThreshold = forbiddenDateThreshold;

        $scope.cancel = function cancel() {
            $modalInstance.dismiss('cancel');
        };

        $scope.specialRequest = function specialRequest() {
            $modalInstance.close('specialRequest');
        };
    }
}

angular
    .module('app')
    .controller(MvPlannerEventUnsupportedModalController.SID, MvPlannerEventUnsupportedModalController);
